/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f7;
  color: #1d1d1f;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.header-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-logo {
  width: 50px;
  height: 50px;
}

.header-tagline {
  margin: 0;
  font-size: smaller;
}

.header-title {
  font-weight: bold;
  text-align: center;
  flex: 1;
}

.header-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-input {
  margin: 5px;
  padding: 5px;
}

/* Footer Styles */
.footer {
  background-color: #f8f8f7;
  color: #1d1d1f;
  padding: 10px;
  text-align: center;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
}

/* Left Panel Styles */
.left-panel {
  background-color: #f8f8f7;
  color: #1d1d1f;
  width: 250px;
  padding: 20px;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
}

.left-panel ul {
  list-style-type: none;
  padding-left: 0;
}

.left-panel li {
  cursor: pointer;
  margin-bottom: 15px;
  padding: 5px;
  transition: background-color 0.3s;
}

.left-panel li:hover {
  background-color: #d0d0d0;
}

.left-panel .active {
  background-color: #e0e0e0;
}

.main-section {
  position: relative; /* Needed for absolute positioning */
  background-color: #f8f8f7; /* Light background */
  border: 1px solid #e1e1e1; /* Subtle border */
  padding: 20px;
  border-radius: 8px;
  gap: 20px;
  width: 100%; /* Ensure it takes the full width */
}

.group-card {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.group-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal {
  font-family: 'Arial Narrow', Arial, sans-serif;
  background-color: #fff;
  padding: 30px;
  border-radius: 0px;
  border: 1px solid #ccc; /* Subtle border */
  overflow: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%; /* Adjust based on your content */
  position: relative; /* Needed for absolute positioning of close button */
  font-weight: bold;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
/* This styles the description text above the radio buttons */
.communication-type-description {
  margin-bottom: -5px; /* Adds some space between the description and the options */
}

/* This styles the container of the radio buttons, arranging them vertically */
.communication-type-options {
  display: flex;
  flex-direction: row; /* Stacks radio buttons vertically */
  gap: 50px; /* Space between each radio button */
}

/* This styles each individual label (and the radio button within it) */
.communication-type-options label {
  display: flex;
  align-items: center; /* Aligns the radio button and label text horizontally */
  gap: 5px; /* Space between the radio button and the label text */
}


.close-button {
  position: absolute;
  top: 10px; /* Adjust as per your design preference */
  right: 10px; /* Adjust as per your design preference */
  background: none;
  border: none;
  font-size: 18px;
  color: #aaa; /* Subtle color */
  cursor: pointer;
}

.close-button:hover {
  color: #888; /* Slightly darker on hover for better user feedback */
}


.modal-body {
  padding: 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #ccc;
}

.modal-footer button {
  background-color: #007bff; /* Styling similar to the image */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/*
.modal-footer button:hover {
  background-color: #005ecb;
}
*/

.modal h2 {
  margin: 0;
}

.modal label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modal input{
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 90%;
}

.modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.add-group-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.add-group-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-group-section button {
  //background-color: #333; /* Darker shade of gray */
  background-color: #007aff; /* Macintosh blue */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-group-section button:hover {
  background-color: #555; /* Slightly lighter on hover */
}


.add-group-button:hover {
  background-color: #0051cc;
}

.group-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.group-row {
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.group-row input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.group-row button {
  padding: 8px 16px;
  border: none;
  background-color: #007aff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.group-row button:hover {
  background-color: #005ecb;
}

.groups-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 50px; /* Give space for the add button */
}

.groups-table th,
.groups-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.groups-table th {
  background-color: #f0f0f0;
}
/*

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff6347; 
}
*/

.main-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; /* Add space below the sub-header */
}

.main-section-header h2 {
  margin: 0;
  color: #333; /* Or any color that fits your design */
  font-size: 24px; /* Or size appropriate for your layout */
}

/* ... rest of your styles ... */



.groups-table input,
.groups-table select {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
}

.groups-table button {
  margin-left: 5px;
  /* Additional button styling */
}

.delete-button {
  color: #ff6347;
  background: none;
  border: none;
  cursor: pointer;
}

/* Include styles for saving and editing state */

.edit-button, .delete-button, .send-message-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #555; /* Subtle icon color */
  font-size: 16px; /* Adjust size as needed */
  margin-left: 5px; /* Space out icons */
}

.edit-button:hover, .delete-button:hover, .send-message-button:hover {
  color: #333; /* Darker color on hover */
}

.dashboard .image-container {
  display: flex;
  align-items: center;
  justify-content: space-around; /* Adjust as needed for your design */
}

.declare-incident-button {
  background: none;
  border: none;
  cursor: pointer;
}

.declare-incident-button img {
  width: 100px; /* Adjust based on your image size */
}

.declare-incident-button:hover {
  opacity: 0.8;
}


.simple-demo .group-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.simple-demo .declare-incident {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.simple-demo input[type="text"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.simple-demo button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.simple-demo button:hover {
  background-color: #0056b3;
}

/* Demp Style */
.simple-demo table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.simple-demo th, .simple-demo td {
  border: 0px solid #ddd;
  text-align: left;
  padding: 8px;
}

.simple-demo th {
  background-color: #f2f2f2;
}

.simple-demo button {
  background-color: #ff0000;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.simple-demo button:hover {
  background-color: #0056b3;
}

